/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, Button, ThemeProvider, Box } from '@mui/material';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { format, eachDayOfInterval, getDate, getDaysInMonth } from 'date-fns';

// PDF
import getDay from 'date-fns/getDay';
import { rgb } from 'polished';
import PDFGenerator from './pdfGenerator';
import PDFGeneratorValor from './pdfGeneratorValor';
import PDFGeneratorTotal from './pdfGeneratorTotal';
import PDFGeneratorTotalSValor from './pdfGeneratorTotalSValor';
import PDFGeneratorTotalVazio from './pdfGeneratorTotalVazio';
import PDFGeneratorTotalVazioSValor from './pdfGeneratorTotalVazioSValor';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#E21450' },
  },
  // overrides: {
  //   MUIDataTableHeadCell: { root: { '&:nth-child(4)': { width: 30, backgroundColor: '#FF0000' } } },
  // },
});

const RelatorioObraMensal = ({
  fdate,
  btnObterDados,
  setBtnObterDados,
  classes,
  dataInicio,
  dataFim,
  searchText,
  mostrarValor,
  mostrarVazio,
  valorHora,
}) => {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  const horas = useSelector((state) => state.relatorios.relatorioMensalHoras.horas);
  const horasVazio = useSelector((state) => state.relatorios.relatorioMensalHoras.horasVazio);
  const horasPDF = useSelector((state) => state.relatorios.relatorioMensalHoras.horasPDF);
  const obras = useSelector((state) => state.relatorios.relatorioMensalHoras.obras);
  const user = useSelector((state) => state.user);

  let filteredHoras = horas;
  let filteredHorasVazio = horasVazio;
  // &&
  // horas.filter(
  //   (hora) =>
  //     (hora.total !== 0 ) ||
  //     (hora.valorPorHora > 0 && hora.valorHora == 0),
  // );
  let filteredHorasPDF = horasPDF;

  // const valorHora = user && user.permissoes && user.permissoes.includes('MOSTRAR_VALOR_HORA');

  const ano = fdate.getFullYear();
  const mes = fdate.getMonth() + 1;
  const data = format(new Date(), 'dd-MM-yyyy');
  let mesAnterior = mes - 1;
  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }
  if (filteredHoras && filteredHoras.length > 0) {
    filteredHoras = filteredHoras.map((a) => {
      a.recursos_id = a.recursos_id.toString();
      a.recursos_nome2 = a.recursos_nome2 === '' ? a.recursos_nome : a.recursos_nome2;
      a.d1 = a.d1.toString();
      a.d2 = a.d2.toString();
      a.d3 = a.d3.toString();
      a.d4 = a.d4.toString();
      a.d5 = a.d5.toString();
      a.d6 = a.d6.toString();
      a.d7 = a.d7.toString();
      a.d8 = a.d8.toString();
      a.d9 = a.d9.toString();
      a.d10 = a.d10.toString();
      a.d11 = a.d11.toString();
      a.d12 = a.d12.toString();
      a.d13 = a.d13.toString();
      a.d14 = a.d14.toString();
      a.d15 = a.d15.toString();
      a.d16 = a.d16.toString();
      a.d17 = a.d17.toString();
      a.d18 = a.d18.toString();
      a.d19 = a.d19.toString();
      a.d20 = a.d20.toString();
      a.d21 = a.d21.toString();
      a.d22 = a.d22.toString();
      a.d23 = a.d23.toString();
      a.d24 = a.d24.toString();
      a.d25 = a.d25.toString();
      a.d26 = a.d26.toString();
      a.d27 = a.d27.toString();
      a.d28 = a.d28.toString();
      if (mes === 2) {
        if (ano % 400 === 0 || (ano % 100 !== 0 && ano % 4 === 0)) {
          a.d29 = a.d29.toString();
        }
      } else {
        a.d29 = a.d29 ? a.d29.toString() : '0';
        a.d30 = a.d30 ? a.d30.toString() : '0';

        if ([1, 3, 5, 7, 8, 10, 12].indexOf(mes) >= 0) {
          a.d31 = a.d31 ? a.d31.toString() : '0';
        }
      }
      a.totalHoras = a.totalHoras.toString();
      if (typeof a.total == 'number') {
        a.total = `${a.total.toFixed(2).toString()} €`;
        // a.total = parseInt(`${a.total}`, 10).toFixed(2);
      }
      if (valorHora && mostrarValor) {
        if (typeof a.valorPorHora == 'number') {
          a.valorPorHora = `${a.valorPorHora.toFixed(2).toString()} €`;
        }
      }

      return a;
    });
  }

  if (filteredHorasVazio && filteredHorasVazio.length > 0) {
    filteredHorasVazio = filteredHorasVazio.map((a) => {
      a.recursos_id = a.recursos_id.toString();
      a.recursos_nome2 = a.recursos_nome2 === '' ? a.recursos_nome : a.recursos_nome2;
      a.d1 = a.d1.toString();
      a.d2 = a.d2.toString();
      a.d3 = a.d3.toString();
      a.d4 = a.d4.toString();
      a.d5 = a.d5.toString();
      a.d6 = a.d6.toString();
      a.d7 = a.d7.toString();
      a.d8 = a.d8.toString();
      a.d9 = a.d9.toString();
      a.d10 = a.d10.toString();
      a.d11 = a.d11.toString();
      a.d12 = a.d12.toString();
      a.d13 = a.d13.toString();
      a.d14 = a.d14.toString();
      a.d15 = a.d15.toString();
      a.d16 = a.d16.toString();
      a.d17 = a.d17.toString();
      a.d18 = a.d18.toString();
      a.d19 = a.d19.toString();
      a.d20 = a.d20.toString();
      a.d21 = a.d21.toString();
      a.d22 = a.d22.toString();
      a.d23 = a.d23.toString();
      a.d24 = a.d24.toString();
      a.d25 = a.d25.toString();
      a.d26 = a.d26.toString();
      a.d27 = a.d27.toString();
      a.d28 = a.d28.toString();
      if (mes === 2) {
        if (ano % 400 === 0 || (ano % 100 !== 0 && ano % 4 === 0)) {
          a.d29 = a.d29.toString();
        }
      } else {
        a.d29 = a.d29 ? a.d29.toString() : '0';
        a.d30 = a.d30 ? a.d30.toString() : '0';

        if ([1, 3, 5, 7, 8, 10, 12].indexOf(mes) >= 0) {
          a.d31 = a.d31 ? a.d31.toString() : '0';
        }
      }
      a.totalHoras = a.totalHoras.toString();
      if (typeof a.total == 'number') {
        a.total = `${a.total.toFixed(2).toString()} €`;
        // a.total = parseInt(`${a.total}`, 10).toFixed(2);
      }
      if (valorHora && mostrarValor) {
        if (typeof a.valorPorHora == 'number') {
          a.valorPorHora = `${a.valorPorHora.toFixed(2).toString()} €`;
        }
      }

      return a;
    });
  }

  if (filteredHorasPDF && filteredHorasPDF.length > 0) {
    filteredHorasPDF = filteredHorasPDF.map((a) => {
      a.recursos_id = a.recursos_id.toString();
      a.recursos_nome2 = a.recursos_nome2 === '' ? a.recursos_nome : a.recursos_nome2;
      a.d1 = a.d1.toString();
      a.d2 = a.d2.toString();
      a.d3 = a.d3.toString();
      a.d4 = a.d4.toString();
      a.d5 = a.d5.toString();
      a.d6 = a.d6.toString();
      a.d7 = a.d7.toString();
      a.d8 = a.d8.toString();
      a.d9 = a.d9.toString();
      a.d10 = a.d10.toString();
      a.d11 = a.d11.toString();
      a.d12 = a.d12.toString();
      a.d13 = a.d13.toString();
      a.d14 = a.d14.toString();
      a.d15 = a.d15.toString();
      a.d16 = a.d16.toString();
      a.d17 = a.d17.toString();
      a.d18 = a.d18.toString();
      a.d19 = a.d19.toString();
      a.d20 = a.d20.toString();
      a.d21 = a.d21.toString();
      a.d22 = a.d22.toString();
      a.d23 = a.d23.toString();
      a.d24 = a.d24.toString();
      a.d25 = a.d25.toString();
      a.d26 = a.d26.toString();
      a.d27 = a.d27.toString();
      a.d28 = a.d28.toString();
      if (mes === 2) {
        if (ano % 400 === 0 || (ano % 100 !== 0 && ano % 4 === 0)) {
          a.d29 = a.d29.toString();
        }
      } else {
        a.d29 = a.d29 && a.d29.toString();
        a.d30 = a.d30 && a.d30.toString();

        if ([1, 3, 5, 7, 8, 10, 12].indexOf(mesAnterior) >= 0) {
          a.d31 = a.d31 && a.d31.toString();
        }
      }
      a.totalHoras = a.totalHoras.toString();
      if (typeof a.total == 'number') {
        a.total = `${a.total.toFixed(2).toString()} €`;
        // a.total = parseInt(`${a.total}`, 10).toFixed(2);
      }
      if (valorHora && mostrarValor) {
        if (typeof a.valorPorHora == 'number') {
          a.valorPorHora = `${a.valorPorHora.toFixed(2).toString()} €`;
        }
      }

      return a;
    });
  }

  const horasTabela = mostrarVazio ? filteredHorasVazio : filteredHoras;

  const columns = [
    {
      name: 'recursos_nome2',
      label: 'Nome',
      options: {
        filter: true,
        sort: false,
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          if (horas.length - 1 != rowIndex) {
            return {
              style: {
                minWidth: '300px',
                maxWidth: '400px',
                border: '1px solid rgb(200, 200, 200)',
              },
            };
          }
          return {
            style: {
              minWidth: '300px',
              maxWidth: '400px',
              border: '1px solid rgb(200, 200, 200)',
            },
          };
        },

        setCellHeaderProps: () => ({
          style: {
            background: rgb(230, 230, 230),
            border: '1px solid rgb(200, 200, 200)',
          },
        }),

        // customBodyRender: (value, tableMeta) => {
        //   const recursoId = tableMeta.rowData[tableMeta.rowData.length - 1];

        //   return (
        //     <div
        //       className="table-btns-categorias"
        //       style={{ display: 'flex', justifyContent: 'flex-start' }}
        //     >
        //       {value ? (
        //         <p>
        //           {recursoId} - {value}
        //         </p>
        //       ) : null}
        //     </div>
        //   );
        // },
      },
    },
  ];

  if (valorHora && mostrarValor) {
    columns.push({
      name: 'valorPorHora',
      label: 'Valor',
      options: {
        filter: true,
        sort: false,
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          if (horas.length - 1 != rowIndex) {
            return {
              style: {
                border: '1px solid rgb(200, 200, 200)',
                textAlign: 'right',
              },
            };
          }
          return {
            style: {
              border: '1px solid rgb(200, 200, 200)',
              textAlign: 'right',
            },
          };
        },
        setCellHeaderProps: () => ({
          style: {
            textAlign: 'center',
            minWidth: '150px',
            background: rgb(230, 230, 230),
            border: '1px solid rgb(200, 200, 200)',
          },
        }),
      },
    });
  }

  const todosDias = eachDayOfInterval({
    start: new Date(dataInicio),
    end: new Date(dataFim),
  });

  todosDias.forEach((TodosDias) => {
    const dia = getDate(TodosDias);
    columns.push({
      name: `d${dia}`,
      label: `${dia}`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          let horasRes;
          let horasLength;
          if (mostrarVazio) {
            horasRes = filteredHorasVazio;
            horasLength = filteredHorasVazio && filteredHorasVazio.length;
          } else {
            horasRes = filteredHoras;
            horasLength = filteredHoras && filteredHoras.length;
          }

          const property = `d${dia}cor`;
          const horasDia = `d${dia}`;
          const ausencias = `d${dia}ausencia`;

          return (
            <>
              {getDay(TodosDias) !== 0 && getDay(TodosDias) !== 6 ? (
                <div style={{ textAlign: 'center' }}>
                  {/* {console.log(typeof horas[index][horasDia])} */}
                  {/* Horas sem faltas */}
                  {horasRes[index][horasDia] > 0 && horasRes[index][ausencias] != null ? (
                    <>
                      {horasRes[index][horasDia]}
                      <br />
                      <p style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                        {' '}
                        {horasRes[index][ausencias]}
                      </p>
                    </>
                  ) : null}
                  {/* Horas com faltas */}
                  {horasRes[index][horasDia] > 0 && horasRes[index][ausencias] == null ? (
                    <>{horasRes[index][horasDia]}</>
                  ) : null}
                  {/* Sem Horas e sem faltas */}
                  {horasRes[index][horasDia] <= 0 && horasRes[index][ausencias] == null
                    ? ' '
                    : null}
                  {/* Sem Horas e com faltas */}
                  {horasRes[index][horasDia] <= 0 && horasRes[index][ausencias] != null ? (
                    <>{horasRes[index][ausencias]}</>
                  ) : null}
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  {/* Horas com faltas */}
                  {horasRes[index][horasDia] > 0 && horasRes[index][ausencias] == null ? (
                    <>{horasRes[index][horasDia]}</>
                  ) : null}
                  {/* Horas sem faltas */}
                  {horasRes[index][horasDia] > 0 && horasRes[index][ausencias] != null ? (
                    <>
                      {horasRes[index][horasDia]}
                      <br />
                      <p style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                        {' '}
                        {horasRes[index][ausencias]}
                      </p>
                    </>
                  ) : null}
                  {/* Sem Horas e com faltas */}
                  {horasRes[index][horasDia] <= 0 && horasRes[index][ausencias] != null ? (
                    <>{horasRes[index][ausencias]}</>
                  ) : null}
                  {/* Sem Horas e sem faltas */}
                  {horasRes[index][horasDia] <= 0 && horasRes[index][ausencias] == null ? (
                    <>
                      {horasLength !== tableMeta.rowIndex ? (
                        <div>{!horasRes[index][ausencias] && <span>-</span>}</div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              )}
              {/* {horas[index][ausencias] != null ? (
                    <>
                      {horas[index][horasDia]}
                      <br />
                      <p style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                        {' '}
                        {horas[index][ausencias]}
                      </p>
                    </>
                  ) : (
                    <>{horas[index][horasDia]}</>
                  )}
                </div>
              ) : (
                <div>
                  {horas[index][ausencias] != null ? <>{horas[index][ausencias]}</> : null}
                  {horas[index][horasDia] > 0 ? (
                    <>{horas[index][horasDia]}</>
                  ) : (
                    <>
                      {horasLength !== tableMeta.rowIndex ? (
                        <div style={{ textAlign: 'center' }}>
                          {!horas[index][ausencias] && <span>-</span>}
                        </div>
                      ) : null}
                    </>
                  )} */}
            </>
          );
        },
        setCellHeaderProps: (value) => {
          return {
            style: {
              background: 'rgb(230, 230, 230)',
              border: '1px solid rgb(200, 200, 200)',
              textAlign: 'center',
            },
          };
        },
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          const cor = `d${dia}cor`;
          const horasDia = `d${dia}`;
          const ausencias = `d${dia}ausencia`;
          let horasRes;
          let horasLength;
          if (mostrarVazio) {
            horasRes = filteredHorasVazio;
            horasLength = filteredHorasVazio && filteredHorasVazio.length;
          } else {
            horasRes = filteredHoras;
            horasLength = filteredHoras && filteredHoras.length;
          }

          if (getDay(TodosDias) === 0 || getDay(TodosDias) === 6) {
            if (horasRes[rowIndex][horasDia] > 0) {
              return {
                style: {
                  background: `${horasRes[rowIndex][cor]}`,
                  border: '1px solid rgb(200, 200, 200)',
                },
              };
            }
            if (horasLength != rowIndex) {
              return {
                style: {
                  border: '1px solid rgb(200, 200, 200)',
                },
              };
            }
          }
          if (horasRes[rowIndex][horasDia] >= 0)
            return {
              style: {
                background: `${horasRes[rowIndex][cor]}`,
                border: '1px solid rgb(200, 200, 200)',
              },
            };

          return null;
        },
      },
    });
  });

  columns.push({
    name: 'totalHoras',
    label: 'Total Horas',
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'right', border: '1px solid rgb(200, 200, 200)' },
      }),
      setCellHeaderProps: () => ({
        align: 'center',
        style: {
          background: rgb(230, 230, 230),
          border: '1px solid rgb(200, 200, 200)',

          borderBottom: '1px solid rgb(200, 200, 200)',
        },
      }),
    },
  });

  if (valorHora && mostrarValor) {
    columns.push({
      name: 'total',
      label: 'Total',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            minWidth: '100px',
            maxWidth: '300px',
            textAlign: 'right',
            border: '1px solid rgb(200, 200, 200)',
            borderRight: '1px solid rgb(200, 200, 200)',
          },
        }),
        setCellHeaderProps: () => ({
          align: 'center',
          style: {
            background: rgb(230, 230, 230),
            border: '1px solid rgb(200, 200, 200)',
            borderRight: '1px solid rgb(200, 200, 200)',
          },
        }),
      },
    });
  }

  columns.push({
    name: 'recursos_id',
    label: ' ',
    options: {
      display: false,
    },
  });

  // eslint-disable-next-line no-unused-vars
  // let Total = 0;

  // if (horas && horas.length > 0) {
  //   // console.log(horas);
  //   if (!horas.find((valTotal) => valTotal.totalHoras === 'Total')) {
  //     // eslint-disable-next-line no-unused-expressions
  //     horas &&
  //       // eslint-disable-next-line array-callback-return
  //       horas.map((dados) => {
  //         Total += parseFloat(dados.total, 10);
  //       });

  //     if (valorHora && mostrarValor) {
  //       horas.push({
  //         totalHoras: 'Total',
  //         total: `${Total.toFixed(2)} €`,
  //       });
  //     } else {
  //       horas.push({
  //         totalHoras: '',
  //         total: '',
  //       });
  //     }
  //   }
  // }

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    download: false,
    print: false,

    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  const handlePdf = (horasPDF) => (e) => {
    e.preventDefault();
    const diasMes = getDaysInMonth(new Date(dataInicio));
    // PDF(ano, mes, data, horas, obras);
    if (mostrarValor && valorHora) {
      PDFGeneratorValor(
        ano,
        mes,
        data,
        horasPDF,
        obras,
        dataInicio,
        dataFim,
        diasMes,
        valorHora,
        mostrarValor,
      );
    } else {
      PDFGenerator(ano, mes, data, horasPDF, obras, dataInicio, dataFim, diasMes);
    }
    setBtnObterDados(false);
  };

  const handlePdfTotal = (horasPDF) => (e) => {
    e.preventDefault();
    const diasMes = getDaysInMonth(new Date(dataInicio));
    // PDF(ano, mes, data, horas, obras);
    if (mostrarValor && valorHora) {
      PDFGeneratorTotal(ano, mes, data, horasPDF, obras, dataInicio, dataFim, diasMes);
    } else {
      PDFGeneratorTotalSValor(ano, mes, data, horasPDF, obras, dataInicio, dataFim, diasMes);
    }
    setBtnObterDados(false);
  };

  const handlePdfTotalVazio = (horasPDF) => (e) => {
    e.preventDefault();
    const diasMes = getDaysInMonth(new Date(dataInicio));
    // PDF(ano, mes, data, horas, obras);
    if (mostrarValor && valorHora) {
      PDFGeneratorTotalVazio(ano, mes, data, horasPDF, obras, dataInicio, dataFim, diasMes);
    } else {
      PDFGeneratorTotalVazioSValor(ano, mes, data, horasPDF, obras, dataInicio, dataFim, diasMes);
    }

    setBtnObterDados(false);
  };

  return (
    <React.Fragment>
      <Col md={12} sm={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box>
            <Button
              size="small"
              // color="primary"
              variant="contained"
              onClick={handlePdfTotalVazio(filteredHorasVazio)}
              style={{ marginTop: '2px', marginRight: '10px', width: '150px' }}
              className={classes.btnLightGreen}
              disabled={!btnObterDados || (horas && horas.length <= 0) || horas == undefined}
            >
              Gerar PDF Vazio
            </Button>
          </Box>
          <Box>
            <Button
              size="small"
              // color="primary"
              variant="contained"
              onClick={
                mostrarVazio ? handlePdfTotal(filteredHorasVazio) : handlePdfTotal(filteredHoras)
              }
              style={{ marginTop: '2px', marginRight: '10px' }}
              className={classes.btnLightGreen}
              disabled={!btnObterDados || (horas && horas.length <= 0) || horas == undefined}
            >
              Gerar PDF
            </Button>
          </Box>
          <Box>
            <Button
              size="small"
              // color="primary"
              variant="contained"
              onClick={handlePdf(filteredHorasPDF)}
              style={{ marginTop: '2px', width: '200px' }}
              className={classes.btnLightGreen}
              disabled={!btnObterDados || (horas && horas.length <= 0) || horas == undefined}
            >
              Gerar PDF por Obra
            </Button>
          </Box>
        </Box>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MUIDataTable
            title=""
            description="Nada para mostrar"
            data={horasTabela}
            columns={columns}
            options={options}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        </ThemeProvider>
      </Col>
    </React.Fragment>
  );
};

export default RelatorioObraMensal;
